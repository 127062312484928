import React, { useState } from 'react'
import Layout from '../layout'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import makeStyles from '@material-ui/styles/makeStyles'
import renderBloks from '@renderBloks'
import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline'
import ACC_THEME from '../../themes'
import PageContainer from '@system/page-container'
import Img from '@system/img'
import { imgMaxWidths } from '@variables/global-variables'
import get from 'lodash/get'

const useStyles = makeStyles({
  body: {
    marginTop: '-186px',
    position: 'relative',
  },
  headerImage: {
    width: '100%',
    height: '80vh',
    objectPosition: 'top',
    maxHeight: '600px',
    '& img': {
      objectFit: 'cover',
    },
  },
})

const CustomersIndex = (props) => {
  const { headerImage, body, metaData, filters, cardModules } = props.blok

  const [isFilteredView, setIsFilteredView] = useState(false)
  const image = get(headerImage, '[0].image')
  const classes = useStyles()

  return (
    <Layout metaData={metaData}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <Box>
          {!!headerImage && (
            <Img
              className={classes.headerImage}
              maxWidth={imgMaxWidths.headerImage}
              src={image}
            />
          )}
          <PageContainer>
            <Box className={classes.body}>
              {!!body && renderBloks(body)}
              {!!filters &&
                renderBloks(filters, {
                  isCustomersModule: true,
                  updateHiddenFieldsValue: (value) => {
                    setIsFilteredView(value)
                  },
                })}
              {cardModules &&
                !isFilteredView &&
                renderBloks(cardModules, {
                  isCustomersModule: true,
                })}
            </Box>
          </PageContainer>
        </Box>
      </ThemeProvider>
    </Layout>
  )
}

export default CustomersIndex
